import Guidelines from '@components/Train/Guidelines';
import { Card, CardTitle, CardDescription } from '@components/ui/card';
import { MAX_TRAINING_IMAGES } from '@lib/global';
import { cn } from '@lib/utils';
import { MoveRight } from 'lucide-react';
import React from 'react';

const steps = [
  {
    step: 'Step 1',
    name: 'Pick a style',
    description: 'by choosing a photo pack',
    src: '/svgs/step-1-pick-a-style-blue.svg',
    cardColor: 'from-[#377FFD] via-[#377FFD] to-[#60C2D8]'
  },
  {
    step: 'Step 2',
    name: `Upload ${MAX_TRAINING_IMAGES} photos`,
    description: 'from your device or Instagram',
    src: '/svgs/step-2-upload-photos-blue.svg',
    cardColor: 'from-[#6035FE] via-[#6035FE] to-[#BBA7FF]'
  },
  {
    step: 'Step 3',
    name: 'Download and share',
    description: 'your new photos',
    src: '/svgs/step-3-download-share-blue.svg',
    cardColor: 'from-[#E8755C] via-[#E8755C] to-[#F0A68D]'
  }
];

export default function HowItWorks() {
  return (
    <Card className="p-6">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none">
          {/* <dl className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-6 text-base leading-7"> */}
          <dl className="flex flex-col lg:flex-row gap-6 text-base leading-7">
            <div className="w-full lg:w-1/3 flex flex-col justify-center items-center">
              <p className="md:text-3xl font-bold tracking-tight text-4xl mb-2 w-full">How it works</p>
              <p className="text-lg font-normal leading-8 mb-2 w-full">
                Generating your own portraits is quick and easy.
              </p>
              <div className="w-full mt-auto f">
                <Guidelines />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-stretch w-full h-fit lg:w-2/3 justify-evenly">
              {steps.map((step, index) => (
                <React.Fragment key={step.name}>
                  <Card
                    className={cn(
                      'p-6 rounded-2xl bg-background dark:bg-muted/50 w-full flex flex-col !shadow-none'
                      // step.cardColor
                    )}
                  >
                    <CardDescription className="text-sm font-medium mb-0 leading-none opacity-80">
                      {step.step}
                    </CardDescription>
                    <CardTitle className="text-lg font-semibold mb-3">{step.name}</CardTitle>
                    <div className="flex-grow flex items-center justify-center">
                      <img src={step.src} alt={step.name} className="w-2/3 h-auto" />
                    </div>
                  </Card>
                  {index < steps.length - 1 && (
                    <MoveRight className="hidden md:block opacity-50 flex-shrink-0 self-center" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </dl>
        </div>
      </div>
    </Card>
  );
}
