import { Svg } from '../../_design_system';
import { ComponentProps } from 'react';
import { Badge } from '@components/ui/badge';
import { Sparkles } from 'lucide-react';
import { cn } from '@lib/utils';

interface AiGeneratedBadgeProps extends ComponentProps<typeof Svg> {
  className?: string;
}

const AiGeneratedBadge = <T extends AiGeneratedBadgeProps>({ className }: T) => {
  return (
    <Badge className={cn('bg-white text-sm md:text-base text-purple-500 whitespace-nowrap shadow-2xl', className)}>
      <Sparkles className="mr-1 text-blue-500 w-3 h-3 md:w-4 md:h-4" />
      <span className="whitespace-nowrap bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
        AI Generated
      </span>
    </Badge>
  );
};

export default AiGeneratedBadge;
